.fallback {
  height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  transition: background-color 2s ease-out;
}

.logo-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
  width: 25%;
}

.loader-text {
  padding: 5px;
  color: white;
}

.loader {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 5px;
}
