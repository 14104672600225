.marketing-basic-text {
  padding: 35px;
  p,
  h1,
  h2,
  h3,
  h4 {
    text-align: left;
  }
  max-width: 1000px;
  margin: 0 auto;
}

.marketing-list-textonly {
  list-style-type: none;
}

.marketing-textpage-header {
  margin: 55px 0 15px 0;
}
