#no-match-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(240, 240, 240, 0.8);
}

#no-match-container {
  font-size: 24px;
  color: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  background-color: rgba(10, 63, 84, 0.95); // same base color #0a3f54
  margin: 80px 25px 0px 25px;
  padding: 25px;
  min-width: 320px;
  max-width: 700px;
}

.no-match-title {
  margin-bottom: 35px;
}

.no-match-text {
  font-size: 18px;
}
