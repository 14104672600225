body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
  box-sizing: border-box;
}

#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-mck {
  color: #fff !important;
  background-color: #0a3f54 !important;
  background: linear-gradient(180deg, #475d79 0%, #0a3f54 100%);
  border: 1px solid #0a3f54;
  text-transform: uppercase;
  font-size: 14px !important;
}

.btn-mck-gray {
  color: #fff !important;
  background-color: #0a3f54 !important;
  background: linear-gradient(180deg, #475d79 0%, #0a3f54 100%);
  border: 1px solid #0a3f54;
  text-transform: uppercase;
  font-size: 14px !important;
}

.btn-mck-light {
  color: #fff !important;
  background-color: #0a3f54 !important;
  background: linear-gradient(180deg, #005f98 0%, #005f98 27%, #003a5d 100%);
  border: 1px solid #0a3f54;
  text-transform: uppercase;
  font-size: 12px !important;
  font-weight: 600;
}

.btn-mck-outline {
  color: #0a3f54 !important;
  background-color: #fff !important;
  border: 1px solid #0a3f54;
  text-transform: uppercase;
  font-size: 14px !important;
}
