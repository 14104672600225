.App {
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: 'Source Sans Pro', sans-serif;
}

.App-link {
  color: #61dafb;
}

#chartjs-tooltip {
  z-index: 900;
}

#filter-bar {
  background-color: #fafafa;
  padding-top: 12px;
  position: fixed;
  width: calc(100% - 200px);
  top: 53px;
  z-index: 101;
  margin-bottom: 10px;
  margin-right: -13px;
  transition: all 0.5s;
  &.minimized {
    width: 100% !important;
    transition: all 0.5s;
  }
}

#filter-bar-floorplan {
  background-color: #fafafa;
  padding-top: 20px;
  padding-bottom: 5px;
  position: fixed;
  width: calc(100% - 200px);
  top: 90px;
  z-index: 101;
  margin-bottom: 10px;
  margin-right: -13px;
  transition: all 0.5s;
  &.minimized {
    width: 100% !important;
    transition: all 0.5s;
  }
}

#back-bar {
  background-color: #fafafa;
  padding-top: 15px;
  padding-bottom: 15px;
  position: fixed;
  width: calc(100% - 200px);
  top: 40px;
  z-index: 102;
  margin-bottom: 20px;
  transition: all 0.5s;
  &.minimized {
    width: 100% !important;
    transition: all 0.5s;
  }
}

#header-filters-more {
  margin-left: 1px;
  background-color: #fafafa;
  width: 100%;
  position: fixed;
  z-index: 1;
}

#sidebar {
  min-width: 200px;
  max-width: 200px;
  min-height: 100vh;
  background: #0a3f54;
  color: #fff;
  transition: all 0.5s;
  position: relative;
  z-index: 10;
  overflow-y: auto;
}
#admin-sidebar {
  min-width: 200px;
  max-width: 200px;
  min-height: 100vh;
  background: #003a5d;
  color: #fff;
  transition: all 0.5s;
  position: relative;
  z-index: 10;
  overflow-y: auto;
}

.sidebar-header {
  width: 100%;
}

#sidebar.active {
  margin-left: -200px;
}
#admin-sidebar.active {
  margin-left: -200px;
}

#content {
  width: 100%;
  min-height: 100vh;
  &.no-height {
    min-height: initial;
  }
  // transition: all 0.5s;
}

.leaf-node .rv-treemap__leaf {
  font-size: 0.75rem;
  background-color: #fff;
  text-align: left;
  cursor: pointer;
  transition: all 250ms ease-out;
  color: #000 !important;
}

.main-content {
  background-color: #fafafa;
}

.main-container {
  background-color: #f5f6f7;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 110px;
  position: relative;
  z-index: 5;
  &.no-offset {
    margin-top: 60px;
  }
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.border-b {
  border-bottom: solid 1px #ccc;
}

.border-t {
  border-top: solid 1px #ccc;
}

.border-l {
  border-left: solid 1px #ccc;
}

.border-r {
  border-right: solid 1px #ccc;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.chartToolTipContainer {
  background-color: #4f6476;
  font-size: 9px;
  color: #cad1d6;
  max-width: 100px;
  border-radius: 2px;
}

.chartToolTipContainer.max {
  max-width: 200px;
}

.chartToolTipContainer.meta {
  max-width: 300px;
}

.chartToolTipContainer .color-chip {
  border-radius: 25px;
  height: 5px;
  width: 5px;
  margin-top: 5px;
  margin-left: 5px;
}

.chartToolTipContainer table td {
  vertical-align: top;
  padding: 5px;
}

.chartToolTipContainer table td.metadata {
  font-size: 8px;
  background-color: #364859;
  text-align: center;
}

.chartToolTipContainer .white {
  color: #fff;
  font-size: 11px;
}

.chartToolTipContainer .title {
  font-size: 9px;
  color: #fff;
}

.chartToolTipContainer .white-big {
  color: #fff;
}

.chartToolTipContainer .big {
  font-size: 12px;
  font-weight: 700;
}

.view-more {
  font-size: 0.9rem;
  color: #0a3f54;
  cursor: pointer;
  font-weight: 700;
}

.no-data {
  color: #4F6476;
  font-size: 14px;
  font-weight: 700;
}

.csv-dl {
  button,
  button:hover,
  button:active {
    color: #0a3f54;
  }
  .btn-link {
    padding: 0 10px 0 0;
    margin-top: -4px;
  }
  .fa-ellipsis-v {
    height: 15px;
  }
  .loader {
    padding: 10px;
    color: '#000';
  }
  .dropdown-menu {
    padding: 0;
    font-size: 0.7rem;
    text-align: right;
    width: 100px;
    min-width: 100px;
    .dropdown-item,
    .dropdown-item:hover,
    .dropdown-item:active {
      background-color: #fff;
      box-shadow: none;
      padding: 5px 10px;
      text-align: center;
    }
  }
}

.header-filters {
  z-index: 800;
}

.popover {
  z-index: 600 !important;
}

.MEDTour {
  padding: 20px;
  font-size: 0.8rem;
  color: #383b41;
  margin: 0 auto;
  max-width: 680px;
  .tour-header {
    color: #fff;
    background-color: #0a3f54;
    border-radius: 4px 4px 0 0;
    padding: 30px;
    font-size: 18px !important;
    line-height: 18px !important;
    text-align: center;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    margin-bottom: 0;
    h5 {
      font-size: 18px !important;
      line-height: 18px !important;
      text-align: center;
      width: 100%;
    }
  }
  .tour-main {
    background-color: #fff;
    text-align: center;
    width: 100%;
    padding: 30px 30px;
    color: #383b41;
    font-size: 13px;
  }
  .tour-text-main {
    font-weight: 700;
    font-size: 13px;
    margin: 0 auto;
    &.min {
      width: 400px;
    }
  }
  .tour-text-sub {
    font-size: 13px;
    margin: 0 auto;
    text-align: left;
    &.min {
      width: 400px;
    }
  }
  .tour-btn {
    background-color: #0a3f54;
    width: 200px;
  }
}

.override-text-white {
  color: #ffffff !important;
}

.override-text-black {
  color: #000000 !important;
}

.power-monitoring-container {
  background-color: #f5f6f7;
}

.power-monitoring-table{
  width: 100%;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  th {
    top: 0;
    left: 0;
    z-index: 2;
    position: sticky;
    background-color: #fff;
  }
}

.power-monitoring-table tr:hover{
  cursor: pointer;
}

.power-monitoring-card{
  transition: 0.3s;
  min-height: 100%;
  text-align: center;
  .card-body{
    display: flex;
    justify-content: center;
  }
  h5 {
    font-size: 1rem;
  }
  h6{
    font-size: 0.8rem;
  }
}

.card-hover-shadow:hover {
  cursor: pointer;
  filter: drop-shadow(#00000063 0.15rem 0.15rem 5px);
}

.power-monitoring-phase-card {
  transition: 0.3s;
  min-height: 100%;
  text-align: left;
}

.power-monitoring-modal{
  min-width: 40vw;
}

.power-monitoring-icon{
  text-transform: capitalize;
  display: inline-block;
}

.power-monitoring-icon-legend{
  text-align: center;
  .power-monitoring-icon{
    margin-right: 16px;
  }
}