.error-card {
  background-color: #ffffff;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  margin: 0;
  max-width: 500px;
  text-align: center;
  padding: 16px;
  box-shadow: 1px 2px 3px hsla(0, 0%, 66.7%, 0.8);
  border-radius: 2px;
}

body {
  background-color: #fafafa;
}

.submit {
  margin: 8px;
}

.logo {
  background-color: #acc2df;
  margin: 8px;
  padding: 4px;
}
